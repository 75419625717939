const select = document.getElementById('select-email-pivod');
const formEmailDefault = document.getElementById('formEmailDefault');
const formEmailDocument = document.getElementById('formEmailDocument');
const formEmailGap = document.getElementById('formEmailGap');
const formEmailEndPivod = document.getElementById('formEmailEndPivod');
const formEmailClosePivod = document.getElementById('formEmailClosePivod');

select.addEventListener('change', (event) => {
    const selectedValue = event.target.value;

    formEmailDefault.style.display = 'none';
    formEmailDocument.style.display = 'none';
    formEmailGap.style.display = 'none';
    formEmailEndPivod.style.display = 'none';
    formEmailClosePivod.style.display = 'none';

    if (selectedValue === '1') {
        formEmailDefault.style.display = 'block';
    } else if (selectedValue === '2') {
        formEmailDocument.style.display = 'block';
    } else if (selectedValue === '3') {
        formEmailGap.style.display = 'block';
    } else if (selectedValue === '4') {
        formEmailEndPivod.style.display = 'block';
    } else if (selectedValue === '5') {
        formEmailClosePivod.style.display = 'block';
    }
});
